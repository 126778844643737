import React from 'react';
import {
	List,
	Datagrid,
	TextField,
	EmailField,
	EditButton,
	SimpleForm,
	TextInput,
	Edit,
} from 'react-admin';

export const RetailerStageList = (props) => (
	<List {...props} title="Retailer Stage Table">
		<Datagrid rowClick="edit">
			<TextField source="id" label="ABN" />
			<TextField source="Sub" label="Sub" />
			<TextField source="FreeTrial" label="FreeTrial" />
			<TextField source="AccountStatus" label="AccountStatus" />
			<TextField source="PhoneNumber" label="PhoneNumber" />
			<TextField source="StoreLogo" label="StoreLogo" />
			<TextField source="ListOfBranches" label="ListOfBranches" />
			<TextField source="BusinessOwnerName" label="BusinessOwnerName" />
			<TextField source="BusinessLocation" label="BusinessLocation" />
			<EmailField source="Email" label="Email" />
			<TextField source="RetailerLevel" label="RetailerLevel" />
			<TextField source="StoreName" label="StoreName" />
			<TextField source="SubscriptionStatus" label="SubscriptionStatus" />
			<TextField source="Category" label="Category" />
			<TextField source="BranchNumber" label="BranchNumber" />
			<TextField source="NumberOfCashRegister" label="NumberOfCashRegister" />
			<TextField source="StripeUUID" label="StripeUUID" />
			<TextField source="StripePID" label="StripePID" />
			<TextField source="StripeSID" label="StripeSID" />
			<TextField source="ExpireTime" label="ExpireTime" />
			<TextField source="RestPeriod" label="RestPeriod" />
			<EditButton />
		</Datagrid>
	</List>
);

export const RetailerStageEdit = (props) => (
	<Edit {...props} title="Retailer Stage Table">
		<SimpleForm>
			<TextField source="ABN" label="ABN" />
			<TextField source="Sub" label="Sub" />
			<TextField source="FreeTrial" label="FreeTrial" />
			<TextInput source="AccountStatus" label="AccountStatus" />
			<TextInput source="PhoneNumber" label="PhoneNumber" />
			<TextInput source="StoreLogo" label="StoreLogo" />
			<TextInput source="ListOfBranches" label="ListOfBranches" />
			<TextInput source="BusinessOwnerName" label="BusinessOwnerName" />
			<TextInput source="BusinessLocation" label="BusinessLocation" />
			<TextInput source="Email" label="Email" />
			<TextInput source="RetailerLevel" label="RetailerLevel" />
			<TextInput source="StoreName" label="StoreName" />
			<TextInput source="SubscriptionStatus" label="SubscriptionStatus" />
			<TextInput source="Category" label="Category" />
			<TextInput source="BranchNumber" label="BranchNumber" />
			<TextInput source="NumberOfCashRegister" label="NumberOfCashRegister" />
			<TextInput source="StripeUUID" label="StripeUUID" />
			<TextInput source="StripePID" label="StripePID" />
			<TextInput source="StripeSID" label="StripeSID" />
			<TextInput source="ExpireTime" label="ExpireTime" />
			<TextInput source="RestPeriod" label="RestPeriod" />
		</SimpleForm>
	</Edit>
);
