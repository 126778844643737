import dataHelper from './dataHelper';
import ApiUrl from './apiUrl';
const url = new ApiUrl();
const data = new dataHelper();
const axios = require('axios');

function parseData(resource, data) {
    switch (resource) {
        case 'Shopper':
            data.id = data.Id
            return data;
        case 'Retailer':
            data.id = data.ABN;
            return data;
        case 'Icon':
            data.id = data.Id;
            return data;
        case 'Category':
            data.id = data.Id;
            return data;
        case 'ShopperStage':
            data.id = data.Id
            return data;        
        case 'RetailerStage':
            data.id = data.ABN;
            return data;
        case 'IconStage':
            data.id = data.Id;
            return data;
        case 'CategoryStage':
            data.id = data.Id;
            return data;
        default:
            throw new Error('Unexpected resource');
    }
}

export default {
    getList: async (resource, params) => {
        const option = {
            method: 'GET',
            headers: { Authorization: localStorage.getItem('token') },
            data: data.getList(resource, params),
            url: url.getList(resource, params),
        };
        try {
            let res = await axios(option);
            if (res.data) {
                for (let i = 0; i < res.data.length; i++) {
                    res.data[i] = parseData(resource, res.data[i]);
                }
                localStorage.setItem(resource + 'Total', res.data.length);
                return Promise.resolve({ data: res.data, total: res.data.length });
            } else {
                return Promise.reject('No record.');
            }
        } catch (err) {
            console.error(err);
            return Promise.reject(err);
        }
    },
    getOne: async (resource, params) => {
        //console.log('getOneParams: ' + JSON.stringify(params, null, 2));
        const option = {
            method: 'GET',
            headers: { Authorization: localStorage.getItem('token') },
            data: data.getOne(resource, params),
            url: url.getOne(resource, params),
        };
        try {
            let res = await axios(option);
            if (res.data) {
                res.data = parseData(resource, res.data);
                console.log(`getOneResData: ${JSON.stringify(res.data, null, 2)}`);
                return Promise.resolve({ data: res.data });
            } else {
                return Promise.reject(new Error('No record'));
            }
        } catch (err) {
            console.error(err);
            return Promise.reject(err);
        }
    },
    getMany: (resource, params) => Promise.reject,
    getManyReference: (resource, params) => Promise.reject,
    create: async (resource, params) => {
        const option = {
            method: 'POST',
            headers: { Authorization: localStorage.getItem('token') },
            data: data.update(resource, params),
            url: url.update(resource, params),
        };
        try {
            let res = await axios(option);
            res.data = parseData(resource, res.data);
            return Promise.resolve({ data: res.data });
        } catch (err) {
            console.error(err);
            return Promise.reject(err);
        }
    },
    update: async (resource, params) => {
        console.log('updateParams: ' + JSON.stringify(params, null, 2));
        const option = {
            method: 'PUT',
            headers: { Authorization: localStorage.getItem('token') },
            data: data.update(resource, params),
            url: url.update(resource, params),
        };
        try {
            let res = await axios(option);
            res.data = parseData(resource, res.data);
            return Promise.resolve({ data: res.data });
        } catch (err) {
            console.error(err);
            return Promise.reject(err);
        }
    },
    updateMany: (resource, params) => Promise.reject,
    delete: async (resource, params) => {
        const option = {
            method: 'DELETE',
            headers: { Authorization: localStorage.getItem('token') },
            data: data.delete(resource, params),
            url: url.delete(resource, params),
        };
        try {
            let res = await axios(option);
            res.data = parseData(resource, res.data);
            return Promise.resolve({ data: res.data });
        } catch (err) {
            console.error(err);
            return Promise.reject(err);
        }
    },
    deleteMany: (resource, params) => Promise.reject,
};