import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
const axios = require('axios');
export const Development = (props) => (
    <Card>
        <Title title="Development Guide" />
        <CardContent>
            Useful links:
            <ul>
                <li><a href='https://fontawesome.com/icons?d=gallery' target='_blank'>Search icons</a></li>
                <li><a href='https://app.lucidchart.com/invitations/accept/47ad6850-419e-42f5-877e-ba0ea5b340ca' target='_blank'>Lucidchart - REPLACED WITH draw.io</a></li>
                <li>Swaggerhub: </li>
                <ul>
                    <li><a href='https://app.swaggerhub.com/apis-docs/1Receipt/1receipt-nodejs-stage-api/' target='_blank'>node.js stage</a></li>
                    <li><a href='https://app.swaggerhub.com/apis-docs/1Receipt/1receipt-nodejs-prod-api/' target='_blank'>node.js prod</a></li>
                </ul>
            </ul>
        </CardContent>
        <hr />
        <CardContent>
            <div>
                Admin idToken
                <button onClick={() => { navigator.clipboard.writeText(localStorage.getItem("token")) }}>Copy</button>
            </div>
        </CardContent>
        <hr />
        <CardContent>
            <div>
                Auto generate $6 receipt
                <button onClick={async () => {
                    const date = new Date();
                    let timestring = '';
                    timestring = timestring + date.getFullYear();
                    if (date.getMonth() + 1 < 10) {
                        timestring = timestring + '0';
                    }
                    timestring = timestring + (date.getMonth() + 1);
                    timestring = timestring + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds()
                    const option = {
                        method: 'POST',
                        headers: { Authorization: localStorage.getItem('token') },
                        data: {
                            "id": "12345678901-41627947-" + timestring,
                            "abn": "12345678901",
                            "shopperId": "41627947",
                            "purchaseDateTime": date.toISOString(),
                            "rawReceipt": "\\u001b@\\u001b@\\u001ba\\u0001\\u001b!\\u0018\\nTest shop\\n\\u001b@\\u001ba\\u0001\\u001b!\\u0000Tax No.: 123456789\\r\\n\\n\\u001b@\\u001ba\\u0000\\u001b!\\u0000------------------------------------------\\n\\u001b@\\u001ba\\u0000\\u001b!\\u0018Receipt No.: 20-200-000019\\n\\u001b@\\n10/7/2020 1:15:32 PM\\r\\nUser: Renee Lu\\r\\nOrder No.: 21\\r\\n------------------------------------------\\r\\nTest Prod 1 (00000001)\\r\\n3x 2.00                               6.00\\r\\n------------------------------------------\\r\\nItems count: 1\\r\\n==========================================\\r\\nSubtotal:                             5.45\\r\\nTax 10%:                              0.55\\r\\n\\u001b@\\u001ba\\u0000\\u001b!\\u0018TOTAL:                                6.00\\n\\u001b@==========================================\\r\\nCash:                                 6.00\\r\\n------------------------------------------\\r\\nPaid amount:                          6.00\\r\\nChange:                               0.00\\r\\n\\u001b@\\u001ba\\u0000\\u001b!\\u0000\\n\\u001b@\\u001ba\\u0001\\u001dhP\\u001dw\\u0001\\u001df\\u0000\\u001dH\\u0002\\u001dkE\\r20-200-000019\\u001dd\\u0003\\r\\n\\u001d@\\u001b@\\n\\n\\n\\n\\u001bi",
                            "items": "[{\"Name\": \"Test Prod 1 (00000001)\",\"Count\": \"3\",\"Price\": \"2.00\"}]",
                            "totalAmount": 6.0,
                            "gst": 0.6,
                            "authType": "admin"
                        },
                        url: 'https://nodejs-api-stage.1receipt.io/api/receipt',
                    };
                    try {
                        let res = await axios(option);
                        console.log(JSON.stringify(res, null, 2));
                    } catch (err) {
                        console.error(err.message);
                    }
                }}>Generate for shopperId #41627947</button>
            </div>
        </CardContent>
        <hr />
        <CardContent>
            <div>
                Retailer Dev Demo Account
            </div>
            <div>
                ABN: 12345678910 <br />
                <button onClick={() => { navigator.clipboard.writeText('12345678910') }}>Copy the ABN</button>
            </div>
            <div>
                Password: RetailerDemo2000@ <br />
                <button onClick={() => { navigator.clipboard.writeText('RetailerDemo2000@') }}>Copy the Password</button>
            </div>
        </CardContent>
        <hr />
        <CardContent>
            <div>
                Retailer Portal Demo Prod Account
            </div>
            <div>
                ABN: 98765432111 <br />
                <button onClick={() => { navigator.clipboard.writeText('98765432111') }}>Copy the ABN</button>
            </div>
            <div>
                Password: 1receiptDemo@ <br />
                <button onClick={() => { navigator.clipboard.writeText('1receiptDemo@') }}>Copy the Password</button>
            </div>
        </CardContent>
        <hr />
        <CardContent>
            <div>
                Retailer Test Account
            </div>
            <div>
                ABN: 12345678901 <br />
                <button onClick={() => { navigator.clipboard.writeText('12345678901') }}>Copy the ABN</button>
            </div>
            <div>
                Password: Password123! <br />
                <button onClick={() => { navigator.clipboard.writeText('Password123!') }}>Copy the Password</button>
            </div>
        </CardContent>
        <hr />
        <CardContent>
            <div>
                Shopper Demo Prod Account
            </div>
            <div>
                Username: faraji_shaz@hotmail.com <br />
                <button onClick={() => { navigator.clipboard.writeText('faraji_shaz@hotmail.com') }}>Copy the Username</button>
            </div>
            <div>
                Password: Password4426@ <br />
                <button onClick={() => { navigator.clipboard.writeText('Password4426@') }}>Copy the Password</button>
            </div>
        </CardContent>
        <hr />
        <CardContent>
            <div>
                Shopper Test Account
            </div>
            <div>
                Username: faraji_shaz@hotmail.com <br />
                <button onClick={() => { navigator.clipboard.writeText('faraji_shaz@hotmail.com') }}>Copy the Username</button>
            </div>
            <div>
                Password: ShopperTest123! <br />
                <button onClick={() => { navigator.clipboard.writeText('ShopperTest123!') }}>Copy the Password</button>
            </div>
        </CardContent>
        <hr />
        <CardContent>
            <div>
                Shopper Test Account - empty state
            </div>
            <div>
                Username: founder@1receipt.io <br />
                <button onClick={() => { navigator.clipboard.writeText('founder@1receipt.io') }}>Copy the Username</button>
            </div>
            <div>
                Password: Password123! <br />
                <button onClick={() => { navigator.clipboard.writeText('Password123!') }}>Copy the Password</button>
            </div>
        </CardContent>
        <hr />
        <CardContent>
            <div>
                Developer Sandbox Account
            </div>
            <div>
                Username: dev@1receipt.io <br />
                <button onClick={() => { navigator.clipboard.writeText('dev@1receipt.io') }}>Copy the Username</button>
            </div>
            <div>
                Password: Password123! <br />
                <button onClick={() => { navigator.clipboard.writeText('Password123!') }}>Copy the Password</button>
            </div>
        </CardContent>
    </Card>
);