import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import Amplify, { Auth } from 'aws-amplify';

Amplify.configure({
	Auth: {
		// REQUIRED - Amazon Cognito Region
		region: 'ap-southeast-2',

		// OPTIONAL - Amazon Cognito User Pool ID
		userPoolId: 'ap-southeast-2_iTEnlHbkk',

		// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
		mandatorySignIn: false,

		// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
		userPoolWebClientId: '107gne5rl0ok6obgksvkbpkv2n',

		// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
		mandatorySignIn: true,

		// OPTIONAL - customized storage object
		storage: null,

		// OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
		authenticationFlowType: 'USER_SRP_AUTH',

		// OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
		clientMetadata: {
			myCustomKey: 'myCustomValue',
		},

		// OPTIONAL - Hosted UI configuration
		oauth: {
			domain: 'https://1receipt-admin.auth.ap-southeast-2.amazoncognito.com',
			scope: [
				'phone',
				'email',
				'profile',
				'openid',
				'aws.cognito.signin.user.admin',
			],
			redirectSignIn: 'http://localhost:3000/',
			redirectSignOut: 'http://localhost:3000/',
			responseType: 'token', // or 'token', note that REFRESH token will only be generated when the responseType is code
		},
	},
});

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
