import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { Admin, Resource, Layout } from 'react-admin';
import { ShopperList, ShopperEdit } from './components/shoppers';
import { RetailerList, RetailerEdit } from './components/retailers';
import { IconList, IconEdit, IconCreate } from './components/icons';
import { CategoryList, CategoryEdit, CategoryCreate } from './components/categories';
import { ShopperStageList, ShopperStageEdit } from './components/shoppersStage';
import { RetailerStageList, RetailerStageEdit } from './components/retailersStage';
import { IconStageList, IconStageEdit, IconStageCreate } from './components/iconsStage';
import { CategoryStageList, CategoryStageEdit, CategoryStageCreate } from './components/categoriesStage';
import { Helper } from './components/helper';
import { DBHelper } from './components/dBHelper';
import { PinpointEvent } from './components/pinpointEvent';
import { Development } from './components/development';
import authProvider from './providers/authProvider';
import Dashboard from './Dashboard';
import dataProvider from './providers/dataProvider';
import ShopperIcon from '@material-ui/icons/Person';
import RetailerIcon from '@material-ui/icons/Store';
import CategoryIcon from '@material-ui/icons/Subject';
import IconIcon from '@material-ui/icons/AddCircle';
import ShopperStageIcon from '@material-ui/icons/PersonOutline';
import RetailerStageIcon from '@material-ui/icons/Storefront';
import CategoryStageIcon from '@material-ui/icons/List';
import IconStageIcon from '@material-ui/icons/AddCircleOutline';
import HelperIcon from '@material-ui/icons/Info';
import EmailIcon from '@material-ui/icons/Email';
import DevelopmentIcon from '@material-ui/icons/DeveloperMode';
import TreeMenu from '@bb-tech/ra-treemenu';
import { getCampaignEvents } from './providers/pinpointEventProvider';

const theme = createMuiTheme({
	palette: {
		type: 'light',
	},
});

function App() {
	const [campaigns, setCampaigns] = React.useState({});
	React.useEffect(() => {
		const init = async () => {
			const token = localStorage.getItem('token');
			if (token === null) return;
			const result = await getCampaignEvents();
			setCampaigns(result);
		}
		init();
	}, [])

	return (
		<Admin
			theme={theme}
			dashboard={Dashboard}
			title="1receipt Management Console"
			dataProvider={dataProvider}
			authProvider={authProvider}
			layout={(props) => <Layout {...props} menu={TreeMenu} />}
		>
			<Resource name="Shoppers" options={{ "label": "Shopper Table", "isMenuParent": true }} />
			<Resource name="Shopper" options={{ "label": "Prod", "menuParent": "Shoppers" }} list={ShopperList} edit={ShopperEdit} icon={ShopperIcon} />
			<Resource name="ShopperStage" options={{ "label": "Stage", "menuParent": "Shoppers" }} list={ShopperStageList} edit={ShopperStageEdit} icon={ShopperStageIcon} />
			<Resource name="Retailers" options={{ "label": "Retailer Table", "isMenuParent": true }} />
			<Resource name="Retailer" options={{ "label": "Prod", "menuParent": "Retailers" }} list={RetailerList} edit={RetailerEdit} icon={RetailerIcon} />
			<Resource name="RetailerStage" options={{ "label": "Stage", "menuParent": "Retailers" }} list={RetailerStageList} edit={RetailerStageEdit} icon={RetailerStageIcon} />
			<Resource name="Categories" options={{ "label": "Category Table", "isMenuParent": true }} />
			<Resource name="Category" options={{ "label": "Prod", "menuParent": "Categories" }} list={CategoryList} edit={CategoryEdit} create={CategoryCreate} icon={CategoryIcon} />
			<Resource name="CategoryStage" options={{ "label": "Stage", "menuParent": "Categories" }} list={CategoryStageList} edit={CategoryStageEdit} create={CategoryStageCreate} icon={CategoryStageIcon} />
			<Resource name="Icons" options={{ "label": "Icon Table", "isMenuParent": true }} />
			<Resource name="Icon" options={{ "label": "Prod", "menuParent": "Icons" }} list={IconList} edit={IconEdit} create={IconCreate} icon={IconIcon} />
			<Resource name="IconStage" options={{ "label": "Stage", "menuParent": "Icons" }} list={IconStageList} edit={IconStageEdit} create={IconStageCreate} icon={IconStageIcon} />
			<Resource name="Docs" options={{ "label": "Docs", "isMenuParent": true }} />
			<Resource name="Development" options={{ "label": "Development", "menuParent": "Docs" }} list={Development} icon={DevelopmentIcon} />
			<Resource name="Helper" options={{ "label": "Helper", "menuParent": "Docs" }} list={Helper} icon={HelperIcon} />
			<Resource name="DBHelper" options={{ "label": "DB Helper", "menuParent": "Docs" }} list={DBHelper} icon={HelperIcon} />
			<Resource name="EmailCampaigns" options={{ "label": "Email Campaigns", "isMenuParent": true }} />
			{Object.entries(campaigns).map(([campaign, events]) =>
				<Resource key={campaign} name={campaign} options={{ "label": campaign, "menuParent": "EmailCampaigns" }} list={() => <PinpointEvent title={campaign} events={events} />} icon={EmailIcon} />
			)}
		</Admin>
	);
}

export default App;
