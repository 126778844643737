import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
import { getTables } from '../providers/dbHelperProvider';

export const DBHelper = (props) => {

    const [tables, setTables] = React.useState([]);
    React.useEffect(() => {
        const init = async () => {
            const tbs = await getTables();
            setTables(tbs);
        }
        init();
    }, []);

    return (
        <Card>
            <Title title="DBHelper" />
            {tables.map(({ TableName, Descriptions, Indexes }) => (
                <>
                    <CardContent key={TableName}>
                        {TableName}
                        <table style={{ textAlign: "left" }}>
                            <tr>
                                <th>Column Name</th>
                                <th>Type</th>
                                <th>Description</th>
                            </tr>
                            {Descriptions.map(({ Name, Description }) => (
                                <tr key={Name}>
                                    <td style={{ verticalAlign: "top" }}>{Name}</td>
                                    <td style={{ verticalAlign: "top" }}>{Description.Type}</td>
                                    <td style={{ verticalAlign: "top" }}>{typeof Description.Value !== 'object'
                                        ? typeof Description.Value === 'string'
                                            ? Description.Value
                                            : Description.Value.toString()
                                        : Object.entries(Description.Value).map(([key, value]) =>
                                            <p key={key} style={{ margin: 0 }}>{`${key}: ${value}`}</p>
                                        )}</td>
                                </tr>
                            ))}
                        </table>
                        {Indexes.length > 0
                            ? (<table style={{ textAlign: "left" }}>
                                <tr>
                                    <th>Index Name</th>
                                </tr>
                                {Indexes.map((o) => (
                                    <tr>
                                        <td>{o}</td>
                                    </tr>
                                ))}
                            </table>)
                            : ''
                        }

                    </CardContent>
                    <hr />
                </>
            ))}
        </Card>
    )
};