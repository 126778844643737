import React from 'react';
import {
	List,
	Datagrid,
	TextField,
	EditButton,
	SimpleForm,
	TextInput,
	Edit,
	Create,
} from 'react-admin';

export const CategoryList = (props) => (
	<List {...props} title="Category Prod Table">
		<Datagrid rowClick="edit">
			<TextField source="id" label="Id" />
			<TextField source="Category" label="Category" />
			<EditButton />
		</Datagrid>
	</List>
);

export const CategoryEdit = (props) => (
	<Edit {...props} title="Category Prod Table">
		<SimpleForm>
			<TextField source="id" label="Id" />
			<TextInput source="Category" label="Category" />
		</SimpleForm>
	</Edit>
);

export const CategoryCreate = (props) => (
	<Create {...props} title="Category Prod Table">
		<SimpleForm>
			<TextInput source="Category" label="Category" />
		</SimpleForm>
	</Create>
);
