import dataProvider from './dataProvider'

export const CountProvider = {
  // Get retailers
  async getRetailers() {
    const {total} = await dataProvider.getList('Retailer');
    return total;
  },
  // Get shoppers
  async getShoppers() {
    const {total} = await dataProvider.getList('Shopper');
    return total;
  },
  // Get retailers stage
  async getRetailersStage() {
    const {total} = await dataProvider.getList('RetailerStage');
    return total;
  },
  // Get shoppers stage
  async getShoppersStage() {
    const {total} = await dataProvider.getList('ShopperStage');
    return total;
  },
}