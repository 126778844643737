import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    SimpleForm,
    TextInput,
    Edit,
    Create
} from 'react-admin';

export const IconStageList = (props) => (
    <List {...props} title="Icon Stage Table">
        <Datagrid rowClick="edit">
            <TextField source="id" label="Id" />
            <TextField source="Icon" label="Icon" />
            <TextField source="Prefix" label="Prefix" />
            <TextField source="IconName" label="IconName" />
            <EditButton />
        </Datagrid>
    </List >
);

export const IconStageEdit = (props) => (
    <Edit {...props} title="Icon Stage Table">
        <SimpleForm>
            <TextField source="id" label="Id" />
            <TextInput source="Icon" label="Icon" />
            <TextInput source="Prefix" label="Prefix" />
            <TextInput source="IconName" label="IconName" />
        </SimpleForm>
    </Edit >
);

export const IconStageCreate = (props) => (
    <Create {...props} title="Icon Stage Table">
        <SimpleForm >
            <TextInput source="Icon" label="DBIcon" />
            <TextInput source="Prefix" label="FAPrefix" />
            <TextInput source="IconName" label="FAIconName" />
        </SimpleForm>
    </Create >
);