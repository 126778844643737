import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';

const JSONBeautify = (props) => {
  const { object, indent } = props;
  if (typeof object === 'object' && object !== null) {
    return <>
      {Object.entries(object).map(([key, value]) => {
        if (Array.isArray(value)) {
          return <div key={key} style={{ marginLeft: indent }}><p style={{ fontWeight: 'bold', margin: '0', display: 'inline' }}>{key}</p>: {value.map((o, index) => <div key={index}><JSONBeautify object={o} indent={indent} /></div>)}</div>;
        }
        return <div key={key} style={{ marginLeft: indent }}><p style={{ fontWeight: 'bold', margin: '0', display: 'inline' }}>{key}</p>: <JSONBeautify object={value} indent={indent} /></div>
      })}
    </>
  }
  return <>{object}</>
}

export const PinpointEvent = (props) => {
  const { title, events } = props;
  return (
    <Card>
      <Title title={title} />
      {events.map((event) => {
        return <React.Fragment key={event.key}>
          <CardContent>
            <div style={{ marginLeft: '12px' }}><p style={{ fontWeight: 'bold', margin: '0', display: 'inline' }}>key</p>: {event.key}</div>
            <JSONBeautify object={event.content} indent='12px' />
          </CardContent>
          <hr />
        </React.Fragment>
      })}
    </Card>
  )
}