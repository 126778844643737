import ApiUrl from "./apiUrl";
const axios = require('axios');
const url = new ApiUrl();

export const getTables = async () => {
  const res = await axios({
    method: 'GET',
    headers: { Authorization: localStorage.getItem('token') },
    url: url.getDBHelper,
  });
  return res.data;
} 
