const domain_nodejs_stage = 'https://nodejs-api-stage.1receipt.io/api/';
const domain_nodejs = 'https://nodejs-api.1receipt.io/api/';

function getDomain(resource) {
    switch (resource) {
        case 'Shopper':
            return domain_nodejs;
        case 'Retailer':
            return domain_nodejs;
        case 'Icon':
            return domain_nodejs;
        case 'Category':
            return domain_nodejs;
        case 'ShopperStage':
            return domain_nodejs_stage;
        case 'RetailerStage':
            return domain_nodejs_stage;
        case 'IconStage':
            return domain_nodejs_stage;
        case 'CategoryStage':
            return domain_nodejs_stage;
        default:
            throw new Error('Unexpected resource');
    }
}
export default class ApiUrl {
    getDBHelper = `${domain_nodejs}db-helper`;
    getEvents = `${domain_nodejs_stage}pinpoint`;
    getList(resource, params) {
        let a;
        let domain = getDomain(resource);
        switch (resource) {
            case 'Shopper':
                a = 'shoppers?authType=admin';
                break;
            case 'Retailer':
                a = 'retailers?authType=admin';
                break;
            case 'Icon':
                a = 'icons';
                break;
            case 'Category':
                a = 'categories';
                break;
            case 'ShopperStage':
                a = 'shoppers?authType=admin';
                break;
            case 'RetailerStage':
                a = 'retailers?authType=admin';
                break;
            case 'IconStage':
                a = 'icons';
                break;
            case 'CategoryStage':
                a = 'categories';
                break;
            default:
                throw new Error('Unexpected resource');
        }
        return domain + a;
    }
    getOne(resource, params) {
        let a;
        let domain = getDomain(resource);
        switch (resource) {
            case 'Shopper':
                a = 'shopper?id=' + params.id + '&authType=admin';
                break;
            case 'Retailer':
                a = `retailer?authType=admin&abn=${params.id}`;
                break;
            case 'Icon':
                a = 'icon?id=' + params.id;
                break;
            case 'Category':
                a = 'category?id=' + params.id;
                break;
            case 'ShopperStage':
                a = 'shopper?id=' + params.id + '&authType=admin';
                break;
            case 'RetailerStage':
                a = `retailer?authType=admin&abn=${params.id}`;
                break;
            case 'IconStage':
                a = 'icon?id=' + params.id;
                break;
            case 'CategoryStage':
                a = 'category?id=' + params.id;
                break;
            default:
                throw new Error('Unexpected resource');
        }
        return domain + a;
    }
    create(resource, params) {
        let a;
        let domain = getDomain(resource);
        switch (resource) {
            case 'Shopper':
                a = 'shopper';
                break;
            case 'Retailer':
                a = 'retailer';
                break;
            case 'Icon':
                a = 'icon';
                break;
            case 'Category':
                a = 'category';
                break;
            case 'ShopperStage':
                a = 'shopper';
                break;
            case 'RetailerStage':
                a = 'retailer';
                break;
            case 'IconStage':
                a = 'icon';
                break;
            case 'CategoryStage':
                a = 'category';
                break;
            default:
                throw new Error('Unexpected resource');
        }
        return domain + a;
    }
    update(resource, params) {
        let a;
        let domain = getDomain(resource);
        switch (resource) {
            case 'Shopper':
                a = 'shopper';
                break;
            case 'Retailer':
                a = 'retailer';
                break;
            case 'Icon':
                a = 'icon';
                break;
            case 'Category':
                a = 'category';
                break;
            case 'ShopperStage':
                a = 'shopper';
                break;
            case 'RetailerStage':
                a = 'retailer';
                break;
            case 'IconStage':
                a = 'icon';
                break;
            case 'CategoryStage':
                a = 'category';
                break;
            default:
                throw new Error('Unexpected resource');
        }
        return domain + a;
    }
    delete(resource, params) {
        let a;
        let domain = getDomain(resource);
        switch (resource) {
            case 'Shopper':
                a = 'shopper?id=' + params.id + '&authType=admin';
                break;
            case 'Retailer':
                a = 'retailer/?abn=' +
                    params.id +
                    '&authType=admin';
                break;
            case 'Icon':
                a = 'icon?id=' + params.id;
                break;
            case 'Category':
                a = 'category?id=' + params.id;
                break;
            case 'ShopperStage':
                a = 'shopper?id=' + params.id + '&authType=admin';
                break;
            case 'RetailerStage':
                a = 'retailer/?abn=' +
                    params.id +
                    '&authType=admin';
                break;
            case 'IconStage':
                a = 'icon?id=' + params.id;
                break;
            case 'CategoryStage':
                a = 'category?id=' + params.id;
                break;
            default:
                throw new Error('Unexpected resource');
        }
        return domain + a;
    }
}