import React from 'react';
import {
	List,
	Datagrid,
	TextField,
	EmailField,
	EditButton,
	SimpleForm,
	TextInput,
	Edit,
} from 'react-admin';

export const ShopperList = (props) => (
	<List {...props} title="Shopper Prod Table">
		<Datagrid rowClick="edit">
			<TextField source="id" label="Id" />
			<TextField source="Name" label="Name" />
			<EmailField source="Email" label="Email"/>
			<TextField source="RegisteredIOS" label="RegisteredIOS" />
			<TextField source="RegisteredAndroid" label="RegisteredAndroid" />
			<TextField source="Sub" label="Sub" />
			<EditButton />
		</Datagrid>
	</List>
);

export const ShopperEdit = (props) => (
	<Edit {...props} title="Shopper Prod Table">
		<SimpleForm>
			<TextField source="id" label="Id" />
			<TextField source="Sub" label="Sub" />
			<TextField source="RegisteredIOS" label="RegisteredIOS" />
			<TextField source="RegisteredAndroid" label="RegisteredAndroid" />
			<EmailField source="Email" label="Email" />
			<TextInput source="Name" label="Name" />
		</SimpleForm>
	</Edit>
);
