export default class dataHelper {
    getList(resource, params) {
        switch (resource) {
            case 'Shopper':
                return {};
            case 'Retailer':
                return {};
            case 'Icon':
                return {};
            case 'Category':
                return {};
            case 'ShopperStage':
                return {};
            case 'RetailerStage':
                return {};
            case 'IconStage':
                return {};
            case 'CategoryStage':
                return {};
            default:
                throw new Error('Unexpected resource');
        }
    }
    // TO DO... {Element does not exist}
    getOne(resource, params) {
        switch (resource) {
            case 'Shopper':
                return {};
            case 'Retailer':
                return {};
            case 'Icon':
                return {};
            case 'Category':
                return {};
            case 'ShopperStage':
                return {};
            case 'RetailerStage':
                return {};
            case 'IconStage':
                return {};
            case 'CategoryStage':
                return {};
            default:
                throw new Error('Unexpected resource');
        }
    }
    create(resource, params) {
        switch (resource) {
            case 'Shopper':
                return {
                    sub: params.sub,
                    name: params.name,
                    email: params.email,
                };
            case 'Retailer':
                return {
                    abn: params.id,
                    sub: params.Sub,
                    phoneNumber: params.PhoneNumber,
                    email: params.Email,
                    retailerLevel: params.RetailerLevel,
                    storeName: params.StoreName,
                    address: params.Address,
                    name: params.Name,
                };
            case 'Icon':
                return {
                    icon: params.Icon,
                    iconName: params.IconName,
                    prefix: params.Prefix,
                };
            case 'Category':
                return {
                    category: params.Category,
                };
            case 'ShopperStage':
                return {
                    sub: params.sub,
                    name: params.name,
                    email: params.email,
                };
            case 'RetailerStage':
                return {
                    abn: params.id,
                    sub: params.Sub,
                    phoneNumber: params.PhoneNumber,
                    email: params.Email,
                    retailerLevel: params.RetailerLevel,
                    storeName: params.StoreName,
                    address: params.Address,
                    name: params.Name,
                };
            case 'IconStage':
                return {
                    icon: params.Icon,
                    iconName: params.IconName,
                    prefix: params.Prefix,
                };
            case 'CategoryStage':
                return {
                    category: params.Category,
                };
            default:
                throw new Error('Unexpected resource');
        }
    }
    update(resource, params) {
        switch (resource) {
            case 'Shopper':
                return {
                    id: params.id,
                    name: params.data.name,
                    authType: "admin",
                };
            case 'Retailer':
                return {
                    abn: params.id,
                    sub: params.Sub,
                    accountStatus: params.AccountStatus,
                    phoneNumber: params.PhoneNumber,
                    email: params.Email,
                    retailerLevel: params.RetailerLevel,
                    storeName: params.StoreName,
                    storeLogo: params.StoreLogo,
                    address: params.Address,
                    name: params.Name,
                    listOfBranchese: params.ListOfBranchese,
                    category: params.Category,
                    subscriptionStatus: params.SubscriptionStatus,
                    branchNumber: params.BranchNumber,
                    numberOfCashRegister: params.NumberOfCashRegister,
                    stripeUUID: params.StripeUUID,
                    stripePID: params.StripePID,
                    stripeSID: params.StripeSID,
                    expireTime: params.ExpireTime,
                    restPeriod: params.RestPeriod,
                    authType: 'admin',
                    authSub: localStorage.getItem('sub'),
                };
            case 'Icon':
                return {
                    id: params.id,
                    icon: params.data.Icon,
                    iconName: params.data.IconName,
                    prefix: params.data.Prefix,
                };
            case 'Category':
                return {
                    id: params.id,
                    category: params.data.Category,
                };
            case 'ShopperStage':
                return {
                    id: params.id,
                    name: params.data.name,
                    authType: "admin",
                };
            case 'RetailerStage':
                return {
                    abn: params.id,
                    sub: params.Sub,
                    accountStatus: params.AccountStatus,
                    phoneNumber: params.PhoneNumber,
                    email: params.Email,
                    retailerLevel: params.RetailerLevel,
                    storeName: params.StoreName,
                    storeLogo: params.StoreLogo,
                    address: params.Address,
                    name: params.Name,
                    listOfBranchese: params.ListOfBranchese,
                    category: params.Category,
                    subscriptionStatus: params.SubscriptionStatus,
                    branchNumber: params.BranchNumber,
                    numberOfCashRegister: params.NumberOfCashRegister,
                    stripeUUID: params.StripeUUID,
                    stripePID: params.StripePID,
                    stripeSID: params.StripeSID,
                    expireTime: params.ExpireTime,
                    restPeriod: params.RestPeriod,
                    authType: 'admin',
                    authSub: localStorage.getItem('sub'),
                };
            case 'IconStage':
                return {
                    id: params.id,
                    icon: params.data.Icon,
                    iconName: params.data.IconName,
                    prefix: params.data.Prefix,
                };
            case 'CategoryStage':
                return {
                    id: params.id,
                    category: params.data.Category,
                };
            default:
                throw new Error('Unexpected resource');
        }
    }
    delete(resource, params) {
        switch (resource) {
            case 'Shopper':
                return {};
            case 'Retailer':
                return {};
            case 'Icon':
                return {};
            case 'Category':
                return {};
            case 'ShopperStage':
                return {};
            case 'RetailerStage':
                return {};
            case 'IconStage':
                return {};
            case 'CategoryStage':
                return {};
            default:
                throw new Error('Unexpected resource');
        }
    }
}