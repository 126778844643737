import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
import { CountProvider } from './providers/countProvider';

const Dashboard = () => {
    const [shopperCount, setShopperCount] = React.useState(0);
    const [shopperStageCount, setShopperStageCount] = React.useState(0);
    const [retailerCount, setRetailerCount] = React.useState(0);
    const [retailerStageCount, setRetailerStageCount] = React.useState(0);

    React.useEffect(() => {
        const init = async () => {
            const rc = await CountProvider.getRetailers();
            const rsc = await CountProvider.getRetailersStage();
            const sc = await CountProvider.getShoppers();
            const ssc = await CountProvider.getShoppersStage();
            setRetailerCount(rc);
            setRetailerStageCount(rsc);
            setShopperCount(sc);
            setShopperStageCount(ssc);
        }
        init();
    }, []);

    return (
        <Card >
            <Title title="1receipt Management Console" />
            <CardContent >Total number of prod shoppers: {shopperCount} </CardContent>
            <CardContent >Total number of prod retailers: {retailerCount} </CardContent>
            <CardContent >Total number of stage shoppers: {shopperStageCount} </CardContent>
            <CardContent >Total number of stage retailers: {retailerStageCount} </CardContent>
        </Card>
        )

}

export default Dashboard;